.app-public {
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #DDD;

  .app-public--form {
    width: 30rem;
    height: 20rem;
    background-color: white;
  }
  .app-public--form {
    padding: 1rem;
  }
}
