.modal-publish {
  background-color: $body-background-color;
  box-shadow: 0 0.5em 2em -0.125em rgba($scheme-invert, 0.8), 0 0px 0 1px rgba($scheme-invert, 0.02);
  max-width: 480px;
  position: absolute;
  left: 5rem;
  right: 5rem;
  top: 5rem;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  overflow: hidden;
}

.modal-publish .frame {
  height: calc(100vh - 2 * 5rem - var(--menu-height));
  max-width: none;
}
