.page {
  padding-top: 1rem;
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: 56rem;
  min-height: 100%;

  transition: all 0.5s ease-in 0;

  .content {
    transition: all 0.5s ease-in 0;
    padding-bottom: 50vh;

    .image.is-pulled-left, .image.is-pulled-right {
      max-width: calc(33% - 2rem);
      margin: 2rem;
    }
  }
  &.is-fullwidth {
    max-width: 100%;
  }
}
