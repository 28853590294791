.frame-bar {
  display: flex;
  align-items: center;
  align-content: flex-start;
  height: var(--menu-height);
}

.frame-scroll {
  height: calc(100% - var(--menu-height));
  overflow: scroll;

  &.is-noscroll {
    overflow: hidden;
  }
}

.frame-header {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 1rem;
}

.frame-content {
  flex-grow: 1;

  width: 100%;
  height: 100%;
  position: relative;

  &.is-padding {
    padding: 0 var(--component-padding-x)
  }
  &.is-flex {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.document-preview {
  background-color:  rgba($scheme-invert, .45);
  min-height: 100%;
  padding: 0.5rem;
  .react-pdf__Document {
    width: 100%;
  }
  .document-preview-page {
    .react-pdf__Page { }
    .document-preview-page-number {
      display: none;
    }
  }
  &.is-overview .react-pdf__Document {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    .document-preview-page {
      .document-preview-page-number {
        display: block;
        text-align: center;
        font-weight: bold;
        color: $white;
      }
    }
  }
}

ul.frame-bar-breadcrumbs {
  padding-left: 0;
  list-style: none;

  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  li {
    display: inline-block;
  }
  a {
    color: inherit !important;
    text-decoration: none !important;
  }
}


.frame-bar-item > .button, .frame-bar-item .dropdown-trigger .button {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  &.is-active {
    background-color: $grey-darker;
    color: $white;
  }
}
