@charset "utf-8";

@import "./variables";

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/minireset.sass";
@import "bulma/sass/base/generic.sass";
@import "bulma/sass/base/helpers.sass";

@import "bulma/sass/form/_all.sass";
@import "bulma/sass/elements/box.sass";
@import "bulma/sass/elements/button.sass";
@import "bulma/sass/elements/content.sass";
@import "bulma/sass/elements/image.sass";
@import "bulma/sass/elements/notification.sass";
@import "bulma/sass/elements/other.sass";
@import "bulma/sass/elements/table.sass";
@import "bulma/sass/elements/tag.sass";
@import "bulma/sass/elements/title.sass";
@import "bulma/sass/elements/progress.sass";

@import "bulma/sass/components/dropdown.sass";
@import "bulma/sass/components/level.sass";
@import "bulma/sass/components/media.sass";
@import "bulma/sass/components/menu.sass";
@import "bulma/sass/components/message.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/components/panel.sass";
@import "bulma/sass/components/tabs.sass";

@import "bulma-switch/src/sass/index.sass";
$checkbox-checked-width: 0.15rem;
@import "bulma-checkradio/src/sass/index.sass";

@import "./toast";
@import "./card";
@import "./frame";
@import "./modal_frame";
@import "./modal_publish";
@import "./dashboard";

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
img, figure {
  user-select: none;
}

dl {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem 1rem;
  dt {
    font-weight: bold;
  }
  dd {
    flex-grow: 1;
  }
}

label.checkbox input {
  margin-right: 0.5rem;
}

.buttons.is-inline-block {
  &:not(:last-child) {
    margin-bottom: 0;
  }
  .button {
    margin-bottom: 0;
  }
}

.buttons.is-fullwidth .button {
  flex-grow: 1;
}

button.anchor {
  @extend a;
  display: inline;
  background-color: transparent;
  border: 0;
  font-size: inherit;
  padding: 0;
}

.tag:empty {
  display: none;
}

.page-loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader {
    height: 5rem;
    width: 5rem;
    border-width: 0.5rem;
    border-left-color: var(--main-menu-background-color);
    border-bottom-color: var(--main-menu-background-color);
    content: '.';
  }
}

:root {
  --component-padding-x: 1rem;
  --component-padding-y: 1rem;
  --animation-time: .2s;

  --menu-width: 15rem;
  --menu-height: 3rem;

  // background-color: #F9F9F9;

  --main-menu-background-color: hsl(0, 0%, 80%);
  --main-menu-color: #EEE;

  --main-menu-highlight-background-color: hsl(0, 0%, 70%);
  --main-menu-highlight-background-color2: hsl(0, 0%, 60%);
  --main-menu-highlight-color: #FFF;

  --component-box-shadow-outer: 0 .125rem .25rem rgba(1,1,1, .075);

  --public-signin-image: url(https://assets1.trustedfamily.net/assets/waco/images/side-login-92f7d5215d226bcebed50b173357b794b244cc13f666a27042a5259a8f6c1693.svg);
  --public-background-color: var(--main-menu-background-color);
}

[data-brand=acme] {
  --main-menu-background-image: url(/logos/acme.svg);
  --main-menu-background-color: hsl(0, 55%, 50%);
  --main-menu-highlight-background-color: hsl(0, 55%, 40%);
  --main-menu-highlight-background-color2: hsl(0, 55%, 30%);
}

[data-brand=wayne] {
  --main-menu-background-color: #71B644;
  --main-menu-background-image: url(https://assets1.trustedfamily.net/assets/waco/images/logo-5dc53856c309e86831ff38605872b18424b10885218a5570612ab08607044a66.svg);
  --main-menu-highlight-background-color: #579C2A;
  --main-menu-highlight-background-color2: hsl(96, 58%, 29%);
}

html, body {
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

#root {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.global-banner, .frame-banner {
  height: var(--menu-height);
  &.notification {
    border-radius: 0;
    margin: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    align-content: flex-start;
  }
}

@import "./public_layout";
@import "./layout";

@import "./page";

.browser {
  .file-select, .file-select-all {
    padding-left: 0.5rem;
    label {
      padding-left: 0.5rem;
    }
  }
  .file-select {
    visibility: hidden;
  }
  .is-selected .file-select {
    visibility: visible;
  }
  .file-select label:before {
    background-color: #FFF;
  }
  tr:hover .file-select {
    visibility: visible;
  }
  tr th, tr td {
    vertical-align: middle;
  }
  tr td {
    cursor: pointer;
  }
  .browser-file-name-column {
    display: flex;
    align-items: center;
    .browser-file-name {
      flex-grow: 1;
    }
    .browser-file-icon {
      color: var(--blue);
      margin: 0 var(--component-padding-x) 0 0;
    }
  }
  .is-selected .browser-file-icon {
    color: inherit;
  }
}

.calendar {
  max-width: calc(7 * 3rem);

  background-color: var(--component-bg-color);
  background-clip: border-box;
  border: var(--component-border-width) solid var(--component-border-color);
  border-radius: var(--component-border-radius);

  .calendar--navigation {
    th {
      padding: 0;
    }
    button {
      border: 0;
      background-color: inherit;
      color: inherit;
    }
  }

  .calendar--navigation--today {
    float: right;
    button {
      padding-right: 0;
      margin-right: 0;
    }
  }
  .calendar--navigation--month {
    display: inline-block;
    min-width: 5.5rem;
  }


  .day {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: 0px;
    padding: 0;
  }
  .day-weekend {
    background-color: var(--grey-lighter);
  }
  .day-out-month {
    color: var(--grey-lighter);
  }
  .day-weekend.day-out-month {
    color: var(--white);
  }
  .day-today .day-digit {
    background-color: var(--red);
    border-radius: 50%;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--white);
    font-weight: bold;
  }
  .day-dots {
    height: 2rem;
    width: 3rem;
    line-height: 0.5rem;
    padding: 0 0.525rem;
  }
  .day-dot {
    width: 0.25rem;
    height: 0.25rem;
    margin: 0 0.125rem;
    border-radius: 50%;
    content: " ";
    display: inline-block;
  }
}

.day-overview {
  .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .media {
    border: 0;
    padding-top: 0;
  }
}

.day-overview--cal {
  display: flex;
  width: 5rem;
  margin-right: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .day-overview--cal--month {
    font-weight: bolder;
  }
  .day-overview--cal--day {
    font-size: 1.5rem;
  }
}
