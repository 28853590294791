.app {
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  position: relative;

  &.has-banner-on-top {
    height: calc(100vh - var(--menu-height));
  }

  &.has-sidebar-visible .frame {
    margin-left: var(--menu-width);
    box-shadow: 0 0.5em 2em -0.125em rgba($scheme-invert, 0.8), 0 0px 0 1px rgba($scheme-invert, 0.02);
  }
}

.sidebar {
  width: calc(var(--menu-width)  + 3em);
  min-width: calc(var(--menu-width)  + 3em);
  padding-left: 3em; // The extra padding, which is off-screen, allows for a nicer drop-shadow when peeking

  max-height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: -3rem;
  bottom: 0;
  z-index: 10;

  transition: left var(--animation-time) ease-in .1s,
              padding-left var(--animation-time) ease-in .1s,
              top var(--animation-time) ease-in .3s,
              bottom var(--animation-time) ease-in .5s,
              border-top-right-radius var(--animation-time) ease-in .5s,
              border-bottom-right-radius var(--animation-time) ease-in .7s,
              box-shadow var(--animation-time) ease-in .1s;

  &.is-collapsed {
    left: calc(-1 * (var(--menu-width) + 3rem));
    top: 2rem;
    bottom: 2rem;
    border-radius: $control-radius;
    &.is-peeking {
      box-shadow: 0 0.5em 2em -0.125em rgba($scheme-invert, 0.8), 0 0px 0 1px rgba($scheme-invert, 0.02);
      left: -3rem;
    }
  }
}

.sidebar-head {
  flex-shrink: 0;
  height: var(--menu-height);
  display: flex;
}

.main-menu {
  flex-grow: 1;
}

.frame {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;

  transition: margin-left var(--animation-time) ease-in 0;
}

.hamburger {
  height: var(--menu-height);
  width: var(--menu-height);

  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;


  cursor: pointer;

  transition: all var(--animation-time);

  &:hover {
    filter: brightness(85%);
  }
}

.sidebar .hamburger {
  background-color: var(--main-menu-background-color);
  color: var(--main-menu-color);
}

.logo {
  flex-grow: 1;

  display: flex;
  align-items: center;
  padding-left: 1rem;

  background-color: var(--main-menu-background-color);
  color: var(--main-menu-color);

  background: var(--main-menu-background-color) var(--main-menu-background-image) no-repeat left center;
  text-indent: -9999px;
}

.main-menu {
  background-color: var(--main-menu-background-color);
  color: var(--main-menu-color);

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.menu--search {
  flex-shrink: 0;

  margin: 0.5rem;

  input {
    filter: opacity(0.5);
    border: 0;
  }
  input:focus {
    filter: opacity(1);
  }
}

.menu--navigation {
  overflow: auto;
  flex-grow: 1;
}

.menu--navigation nav p {
  padding: 0.5em 1rem;
}
.menu--navigation nav a {
  display: flex;
  align-items: center;
  align-content: flex-start;

  padding: 0.5em 1rem;
  color: inherit;
  text-decoration: none !important;

  .svg-inline--fa {
    margin-right: 1rem;
  }

  &:hover, &.is-active {
    background-color: var(--main-menu-highlight-background-color);
    color: var(--main-menu-highlight-color);
  }

  .badge {
    min-width: 1rem;
  }
  .menu--item--name {
    flex-grow: 1;
  }
}

.menu--account {
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  background-color: var(--main-menu-highlight-background-color);
  // box-shadow: $box-shadow-lg;

  .menu--account--item {
    display: block;
    min-height: var(--menu-height);
    height: var(--menu-height);
    padding: 1rem;
    color: inherit !important;
    text-decoration: inherit !important;
    background-color: inherit;
    border: none;
    text-align: left;
    font-size: inherit;
    cursor: pointer;
  }
  .menu--account--item:hover {
    background-color: var(--main-menu-highlight-background-color);
  }

  .menu--account--toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    min-height: var(--menu-height);
    height: var(--menu-height);
    cursor: pointer;
  }
  .menu--account--item:hover {
    background-color: var(--main-menu-highlight-background-color2);
  }

  .menu--account--avatar {
    height: calc(var(--menu-height) - 1rem);
    width: calc(var(--menu-height) - 1rem);
    border-radius: calc((var(--menu-height) - 1rem) / 2);
    margin: 0.5rem;
    // box-shadow: $box-shadow;
  }
  .menu--account--name {
    flex-grow: 1;
  }
  .menu--account--carret {
    width: var(--menu-height);
    text-align: center;
    display: cursor;
  }
}

.modal-account-switcher {
  background-color: $body-background-color;
  box-shadow: 0 0.5em 2em -0.125em rgba($scheme-invert, 0.8), 0 0px 0 1px rgba($scheme-invert, 0.02);
  max-width: 480px;
  padding: 1rem;
  position: absolute;
  left: 5rem;
  right: 5rem;
  top: 5rem;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  overflow: hidden;
}

.bg-rainbow {
  background: linear-gradient(221deg, #ff5110, #e4ff10, #12ff10, #10f2ff, #2010ff, #ff10f6, #ff1010);
  background-size: 400% 400%;

  animation: Rainbow 10s ease infinite;
}
@keyframes Rainbow {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
