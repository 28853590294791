.dashboard {
  width: 100%;
  display: flex;
  padding: 1rem;
}

.dashboard-main {
  flex-grow: 1;
  margin-right: 1rem;
}
.dashboard-side {
  width: 20rem;

  &.is-2x {
    width: 40rem;
  }
}

.dashboard--spotlight .card {
  margin-bottom: 1rem;
}
.dashboard--spotlight a {
  color: white;
}
.dashboard--spotlight .card-img-overlay {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dashboard--announcements {
  margin-bottom: 1rem;
}

.dashboard--announcements > h1, .dashboard--feed > h1 {
  margin-top: 0;
}

.dashboard--newsfeed {
  padding-left: 6rem;
  padding-right: 6rem;
  max-width: 56rem;
  min-height: 100%;

  .card {
    margin-bottom: 2rem;
  }
}

.media.is-file {
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 6px;
}

.file-list {
  margin-top: 1rem;
  .media {
    margin: 0 !important;
    border-radius: 0;
  }
  .media + .media {
    border-top-width: 0;
  }
  .media:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .media:first-child {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

}

.images.is-1x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
.images.is-2x1 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 1rem;
}

.image.is-stacked {
  width: auto;
  display: inline-flex;
  flex-direction: row-reverse;
  & > * {
    height: inherit !important;
    width: auto  !important;
    margin-left: -12px;
    transition: margin-left 0.25s ease;
  }
  & > *:last-child {
    margin-left: 0px;
  }
  &:hover > * {
    margin-left: 0px;
  }
}
