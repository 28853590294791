@import "bulma/sass/components/card.sass";

.card {
  .card-image-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .card-content {
      color: var(--white);
      text-shadow: 11px 1px 10px rgba(0, 0, 0, 0.5);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

.card {
  transition: all 0.2s ease-in-out;
}

.card.is-droptarget {
  transform: scale(1.02);
  box-shadow: 0 0.5em 2em -0.125em rgba(10, 10, 210, 0.8), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.card.is-dragging {
  transform: scale(0.8);
}
