$menu-nested-list-margin: 1rem 0 1rem 1.5rem;

@import "bulma/sass/utilities/initial-variables.sass";
@import "bulma/sass/utilities/functions.sass";
@import "bulma/sass/utilities/derived-variables.sass";

$modal-background-background-color: rgba($scheme-invert, .45) !default;

@import "bulma/sass/utilities/animations.sass";

:root {
  --black: #{$black};
  --black-bis: #{$black-bis};
  --black-ter: #{$black-ter};

  --grey-darker: #{$grey-darker};
  --grey-dark: #{$grey-dark};
  --grey: #{$grey};
  --grey-light: #{$grey-light};
  --grey-lighter: #{$grey-lighter};
  --grey-lightest: #{$grey-lightest};

  --white-ter: #{$white-ter};
  --white-bis: #{$white-bis};
  --white: #{$white};

  --orange: #{$orange};
  --yellow: #{$yellow};
  --green: #{$green};
  --turquoise: #{$turquoise};
  --cyan: #{$cyan};
  --blue: #{$blue};
  --purple: #{$purple};
  --red: #{$red};
}
